<template>
  <ClientOnly>
    <div class="w-full">
      <div class="slider-overflow-blur left-0" />
      <div class="slider-overflow-blur right-0" />
      <swiper
        class="w-full"
        loop
        :breakpoints="breakpoints"
        :modules="[Navigation]"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
      >
        <swiper-slide
          v-for="release in releases"
          :key="`swiper-slide-release-card-${release.id}`"
        >
          <ReleaseCard
            :release="release"
            :show-details="showDetails"
            :vertical="useVerticalCards"
            :show-sponsor="showSponsor"
          />
        </swiper-slide>

        <div class="swiper-button-next" />
        <div class="swiper-button-prev" />
      </swiper>
    </div>
  </ClientOnly>
</template>

<script>
  export default {
    name: "ReleaseSlider",
  }
</script>

<script setup>
  import "swiper/css"
  import "swiper/css/navigation"

  import { Swiper, SwiperSlide } from "swiper/vue"
  import { Navigation } from "swiper/modules"

  defineProps({
    releases: {
      type: Array,
      required: true,
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
    showSponsor: {
      type: Boolean,
      default: true,
    },
    breakpoints: {
      type: Object,
      default: () => ({
        320: {
          slidesPerGroup: 1,
          slidesPerView: 1,
          spaceBetween: 0,
        },
        480: {
          slidesPerGroup: 2,
          slidesPerView: 2,
          spaceBetween: 10,
        },
        640: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          spaceBetween: 10,
        },
      }),
    },
    useVerticalCards: {
      type: Boolean,
      default: false,
    },
  })
</script>

<style lang="scss" scoped>
  .slider-overflow-blur {
    @media (min-width: 1280px) {
      @apply absolute inline-block z-10 top-0 bottom-0;
      @apply w-[10%] h-full;

      width: max(calc((100vw - 1290px) / 2), 40px);
    }
  }

  .swiper {
    overflow: visible;

    .swiper-wrapper {
      padding: 20px 0;
      border: 10px solid blue;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    padding: 20px 10px;
    position: absolute;
    z-index: 500;
    top: 39%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 100ms all;
    color: white;

    &:hover {
      transform: scale(1.25);
    }
  }

  .swiper-button-lock {
    display: none;
  }
</style>
